@font-face {
    font-family: 'Inter';
    src: url(./Inter-Regular.woff) format('woff'),
    url(./Inter-Regular.woff2) format('woff2'),
    url(./Inter-Regular.ttf) format('truetype');
    font-weight: 400;
}
@font-face {
    font-family: 'Inter';
    src: url(./Inter-Medium.woff) format('woff'),
    url(./Inter-Medium.woff2) format('woff2'),
    url(./Inter-Medium.ttf) format('truetype');
    font-weight: 500;
}