.body {
    height: 100%;
    width: 100%;
    margin: 0px auto;
    padding: 0px;
    font-family: 'Inter', Arial, sans-serif;
    font-style: normal;
    color: #ffffff;
    background-color: #000;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
}

html, 
.root {
    height: 100%;
}

.page {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* padding: 30px 10px 0px 10px; */
}

h1,h2,p {
    margin: 0px;
}

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

button {
    border: 0;
    background: transparent;
}

fieldset {
    padding: 0px;
    margin: 0px;
    border: 0px;
}

input:disabled {
    background-color: transparent;
}

a{
    text-decoration: none;
}
