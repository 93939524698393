.form-input {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 17px;
}

.form-input:last-of-type {
    margin-bottom: 0px;
}

.form-input__input {
    border: 3px solid;
    border-radius: 3px;
    background: transparent;
    font-size: 26px;
    font-weight: 500;
    line-height: 25px;
    padding: 16px;
    color: #FFF;
}

.form-input__input:focus{
    border: 3px solid #ffc107 ;
    border-radius: 4px;
    outline: #ffc107 solid 2px;
    caret-color: #ffc107;
    
}

.form-input__icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 23px;
    right: 15px;
    cursor: pointer;
}

.form-input__error {
    margin-top: 10px;
    color: #f6361dcc;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    padding-left: 12px;
}