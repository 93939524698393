.channels {
    display: flex;
    flex-direction: column;
}

.channels__wrap {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 190px);
    overflow-y: scroll;
    visibility: hidden;
}

.channels__list {
    padding: 20px 10px 0px 30px;
}

.channels__list,
.channels__wrap:hover {
    visibility: visible;
}

.channels__wrap::-webkit-scrollbar {
    width: 6px;
}

.channels__wrap::-webkit-scrollbar-thumb {
    background-color: rgba(243, 237, 237, 0.301);
    border-radius: 20px;
}

.channels__wrap::-webkit-scrollbar-track {
    background-color: transparent;
}

.channels__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 9px;
    position: relative;
}

.channels__item:focus {
    outline: none;
}

.channels__item:focus>span {
    color: #ffc107;
}

.channels__icon {
    font-size: 24px;
    color: rgba(243, 237, 237, 0.5);
    cursor: pointer;
}
