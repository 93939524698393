.movieslist {
    width: 100%;
    height: calc(100vh - 163px);
    overflow-y: scroll;
    visibility: hidden;
    padding: 5px 3px 0px;
}

.movieslist__items {
    display: grid;
    grid-template-columns: repeat(auto-fit, 150px);
    grid-template-rows: repeat(auto-fit, 238px);
    justify-content: flex-start;
    column-gap: 37px;
    row-gap: 40px;
}

.movieslist__items,
.movieslist:hover {
    visibility: visible;
}

.movieslist::-webkit-scrollbar {
    width: 6px;
}

.movieslist::-webkit-scrollbar-thumb {
    background-color: rgba(243, 237, 237, 0.301);
    border-radius: 20px;
}

.movieslist::-webkit-scrollbar-track {
    background-color: transparent;
}