.footer {
    display: flex;
    flex-direction: column;
    margin: 0px auto 10px;
    text-align: center;
    flex: 0 0 auto;
}

.footer__link {
    color: #FFF;
    text-decoration: none;
    cursor: pointer;
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    margin: 0px 0px 12px;
}

.footer__link:focus {
    border-radius: 3px;
    outline: #ffc107 solid 3px;
}

.footer__link_lang{
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer__icon {
    padding-right: 7px;
}