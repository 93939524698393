.header {
    display: flex;
    align-items: center;
    margin: 20px 0px;
}

.header__logo {
    width: 65px;
    height: 65px;
    margin-right: 50px;
    margin-left: 30px;
}

.header__navigate {
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: center;
}

.header__links {
    display: flex;
    justify-content: space-around;
    width: 35%;
}

.header__link {
    color: #FFF;
    font-size: 26px;
    font-weight: 500;
    line-height: 29px;
    cursor: pointer;
}

.header__icon {
    width: 30px;
    height: 28px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 1px;
}

.header__icon_settings {
    background-image: url('../../images/icon-gear.png');
}

.header__icon_exit {
    background-image: url('../../images/icons8-shutdown.png');
}

.header__link_active {
    border-bottom: 3px solid #ffc107;
}

.header__settings {
    display: flex;
    justify-content: space-between;
    width: 8%;
} 

 /* .header__icon {
    width: 33px;
    height: 33px;
} */