.exit-popup {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.658);
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

.exit-popup_opened {
    display: flex;
    visibility: visible;
    opacity: 1;
}

.exit-popup__content {
    background: rgb(0, 0, 0);
    border-radius: 20px;
    color: #FFF;
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
    position: relative;
    display: grid;
    row-gap: 50px;
    padding: 40px;
    border: 2px solid #585858;
}

.exit-popup__title {
    font-size: 40px;
    font-weight: 400;
}

.exit-popup__subtitle {
    font-size: 23px;
}

.exit-popup__buttons {
    display: flex;
    justify-content: space-between;
    width: 55%;
    justify-self: center;
}

.exit-popup__button {
    color: #FFF;
    font-size: 23px;
    padding: 30px;
}

.exit-popup__button:focus {
    outline: 6px solid #ffc107;
    border-radius: 0px;
    outline-offset: 2px;
}

.open__focus {
    outline: 6px solid #ffc107;
    border-radius: 0px;
    outline-offset: 2px;
}