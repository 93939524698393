.livetv {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}

.livetv__player {
    padding-top: 6px;
}

.livetv__title {
    font-size: 26px;
    font-weight: 500;
    line-height: 29px;
    margin: 20px 0px 10px;
}

.livetv__program {
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
    
}

.livetv__description {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.livetv__description:focus{
    outline: 4px solid #ffc107;
    outline-offset: 2px;
    background-color: rgba(85, 84, 84, 0.5);
}