.moviecard {
    position: relative;
    width: 150px;
    height: 238px;
    cursor: pointer;
}

.moviecard:focus {
    outline: none;
    box-shadow: 0px 2px 10px 2px #ffc107 ;
}

.moviecard__img {
    width: 100%;
    height: 100%;
}

.moviecard__btn {
    position: absolute;
    left: 6px;
    bottom: 6px;
    display: flex;
    background: black;
    padding: 14px 16px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
}

.moviecard__eye {
    width: 32px;
    height: 22px;
    margin-right: 6px;
}

.moviecard__count {
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
}