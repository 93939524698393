.movies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 30px;
}

.movies__links {
    display: flex;
    justify-content: space-evenly;
    width: 38%;
    margin-bottom: 20px;
}

.movies__item {
    font-size: 26px;
    font-weight: 500;
    line-height: 29px;
}