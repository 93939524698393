.popup {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    /* opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear; */
}

.popup_opened {
    display: flex;
    flex-direction: column;
    visibility: visible;
    /* opacity: 1; */
}

.popup__header {
    display: flex;
    justify-content: space-between;
    padding: 30px 40px;
    align-items: center;
}

.popup__back-btn {
    width: 18%;
    display: flex;
}

.popup__icon {
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.popup__icon:focus {
    outline: 6px solid #ffc107;
    border-radius: 0px;
    outline-offset: 3px;
}

.popup__title {
    font-size: 30px;
    font-weight: 500;
}

.popup__header-buttons {
    display: flex;
    width: 18%;
    justify-content: space-between;
    align-items: center;
}

.popup__play-btn {
    background-color: #ffc107;
    display: flex;
    align-items: center;
    padding: 7px 25px;
    border-radius: 23px;
    cursor: pointer;
}

.popup__play-btn:focus {
    outline: 6px solid #ffc107;
    border-radius: 0px;
    outline-offset: 3px;
}

.popup__play-btn_trailer {
    background-color: #000;
    color: #ffc107;
    font-size: 22px;
    font-weight: 500;
    border: 2px solid rgba(85, 84, 84, 0.5);
}

.popup__play-text {
    font-size: 22px;
    font-weight: 500;
    margin-right: 5px;
}

.popup__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
}

.popup__main::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2A2C2F;
    opacity: 0.5;
    z-index: 1;
}

.popup__info {
    display: flex;
    z-index: 2;
    width: 55%;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 35px;
    margin: 8px 0px 30px 300px;
}

.popup__info:focus {
    outline: 6px solid #ffc107;
    border-radius: 0px;
    outline-offset: 2px;
}

.popup__info-item {
    display: flex;
    flex-direction: column;
}

.popup__info-item_rating {
    flex-direction: row;
    width: 25%;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.popup__info-title {
    font-size: 25px;
    font-weight: 500;
    padding-bottom: 7px;
}

.popup__info-title_rating {
    font-size: 37px;
}

.popup__info-subtitle {
    font-size: 20px;
    font-weight: 400;
}

.popup__description {
    display: flex;
    width: 60%;
    z-index: 2;
    justify-content: space-between;
    margin-left: 300px;
}

.popup__image {
    width: 300px;
    height: 470px;
    object-fit: cover;
}

.popup__image:focus {
    outline: 6px solid #ffc107;
    border-radius: 0px;
    outline-offset: 2px;
}

.popup__text {
    width: 70%;
    font-size: 26px;
    font-weight: 400;
    padding-top: 40px;
}

.popup__text:focus {
    outline: 6px solid #ffc107;
    border-radius: 0px;
    outline-offset: 2px;
}

.popup_movie_style {
    position: fixed;
}