.channel {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 428px;
}

.channel:focus { 
    outline: 4px solid #ffc107; 
    outline-offset: 2px; 
    background-color: rgba(85, 84, 84, 0.5);
}

.channel__icon {
    width: 60px;
    height: 60px;
    margin-right: 20px;
}

.channel__name {
    margin: 0px;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}