.programs {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 143px);
    overflow-y: scroll;
    visibility: hidden;
}

.programs__list {
    padding: 6px 10px 0px 30px;
}

.programs__list,
.programs:hover{
    visibility: visible;
}

.programs::-webkit-scrollbar {
    width: 6px;
}

.programs::-webkit-scrollbar-thumb {
    background-color: rgba(243, 237, 237, 0.301);
    border-radius: 20px;
}

.programs::-webkit-scrollbar-track {
    background-color: transparent;
}

.programs__list_empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    padding-top: 250px;
}

.programs__icon {
    width: 40px;
    height: 40px;
}

.programs__text {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
}

