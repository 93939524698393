.auth {
    padding: 200px 0px 0px;
    width: 35%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}

.auth__title {
    margin: 0px;
    font-size: 50px;
    font-weight: 500;
    line-height: 42px;
    padding-bottom: 35px;
    text-align: center;
}

.auth__form {
    display: flex;
    flex-direction: column;
}

.auth__error {
    margin-bottom: 16px;
    color: #f6361dcc;
    font-size: 20px;
    line-height: 19px;
    font-weight: 400;
    text-align: center;
    display: none;
}

.auth__error_visible {
    display: block;
}

.auth__inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.auth__submit-button {
    border-radius: 3px;
    background: #ffc107;
    border: 0;
    color: #000;
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
    padding: 17px;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    cursor: pointer;
}

/* .auth__submit-button:focus{
    outline: #ffc107 solid 1px;
    box-shadow: #ffc107 0px 1px 0px, #ffc107 0px 0px 8px;
} */

.auth__submit-button_disabled {
    background: #212020;
    color: #9c9b98;
    cursor: default;
}

.auth__link {
    color: #FFF;
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    margin: 18px 0px 0px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
}

/* .auth__link:focus {
    border-radius: 3px;
    border: 1px solid #ffc107 ;
    outline: #ffc107 solid 2px;
} */

.active {
    border-color: #ffc107 !important;
    /* border-radius: 4px; */
    border: 1px solid #ffc107;
    /* outline: #ffc107 solid 2px; */
}